module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"5","matomoUrl":"https://matomo.sharoken.cloud/","siteUrl":"https://qualriseconsulting.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mautic/gatsby-browser.js'),
      options: {"plugins":[],"hostUrl":"https://mautic.sharoken.cloud","head":false,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
